import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaCrown, FaCheck } from 'react-icons/fa';

const Section = styled.section`
  padding: 160px 24px;
  background: #000;
  color: #fff;
  overflow: hidden;
  position: relative;

  &::before {
    // content: "PARKINGKARMA";
    position: absolute;
    font-size: 200px;
    font-weight: 900;
    opacity: 0.03;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    pointer-events: none;
  }
`;

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const SectionHeader = styled.div`
  text-align: left;
  margin-bottom: 120px;
  padding-left: 40px;
  border-left: 4px solid #FF5A5A;
`;

const Overline = styled.span`
  color: #FF5A5A;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 8px;
  display: block;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: clamp(3rem, 8vw, 5rem);
  font-weight: 900;
  color: #fff;
  margin-bottom: 24px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: -1px;
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.7);
  max-width: 600px;
  line-height: 1.6;
  font-weight: 300;
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 40px;
  margin-top: 60px;
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
`;

const PricingCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 40px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
`;

const PlanContent = styled.div`
  flex-grow: 1;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  padding-top: 20px;
`;

const PriceWrapper = styled.div`
  margin: 24px 0;
  text-align: center;
`;

const Price = styled.h3`
  font-size: 3.5rem;
  color: #fff;
  font-weight: 700;
  
  span {
    font-size: 1.25rem;
    opacity: 0.7;
  }
`;

const Savings = styled.div`
  color: #FF5A5A;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 8px;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 32px 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 16px;
  font-size: 1.1rem;
  
  svg {
    color: #FF5A5A;
  }
`;

const CTAButton = styled.a`
  width: 100%;
  padding: 16px;
  background: #FF5A5A;
  border: none;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  
  &:hover {
    background: #ff7070;
  }
`;

const PricingSection = () => {
  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };

  return (
    <Section id="pricing">
      <Container>
        <SectionHeader>
          <Overline>Free App</Overline>
          <Title>100% Free</Title>
          <Subtitle>
            Download our app for free. No subscriptions, no fees, no commitments.
          </Subtitle>
        </SectionHeader>

        <PricingGrid>
          <PricingCard
            variants={cardVariants}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.5 }}
            featured
          >
            <PlanContent>
              <h3>Free Access</h3>
              <PriceWrapper>
                <Price>$0</Price>
              </PriceWrapper>
              <FeatureList>
                <FeatureItem><FaCheck /> Find available parking instantly</FeatureItem>
                {/* <FeatureItem><FaCheck /> Real-time meter maid alerts</FeatureItem> */}
                <FeatureItem><FaCheck /> Turn-by-turn navigation</FeatureItem>
                <FeatureItem><FaCheck /> Video protection for your car</FeatureItem>
                <FeatureItem><FaCheck /> All parking rules & restrictions</FeatureItem>
              </FeatureList>
            </PlanContent>
            <ButtonContainer>
              <CTAButton
                href="https://apps.apple.com/us/app/parkingkarma-sf/id6470849458"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Free App
              </CTAButton>
            </ButtonContainer>
          </PricingCard>
        </PricingGrid>
      </Container>
    </Section>
  );
};

export default PricingSection;
