import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import Features from '../components/Features';
import Services from '../components/Services';
import Footer from '../components/Footer';
import StopWasting from "./../components/StopWastingTime"
import SecondPage from '../components/AboutUs';
// import Screenshots from "./../components/Screenshots/screenshots"
import DiscordCommunity from '../components/JoinDiscord';
const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    return (
        <>
            <Navbar toggle={toggle} />
            <HeroSection />
            <Features />
            {/* <Screenshots /> */}

            {/* <Services /> */}
            <SecondPage />

            <StopWasting />
            <DiscordCommunity /> {/* Add this component */}

            <Footer />
        </>
    );
};

export default Home;
