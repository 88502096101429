import React from 'react';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper } from './HeroElements';
import { ReactComponent as LocationMarker } from "../../images/location.svg";
import styled, { keyframes } from "styled-components";

// Define keyframes
const fadeIn = keyframes`
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
`;

// Update HeroContent to use the fadeIn animation
const AnimatedHeroContent = styled(HeroContent)`
    animation: ${fadeIn} 1s ease-out;
`;

// Floating emoji styled component
const FloatingEmoji = styled.span`
    display: inline-block;
    animation: ${float} 3s ease-in-out infinite;
`;

const LocationMarkerIcon = styled(LocationMarker)`
    display: inline-block;
    vertical-align: middle;
    transform: translateY(-10%);
    margin-right: -24px;
    margin-left: -16px;
    width: 100px;
    height: 100px;
    
    @media screen and (max-width: 768px) {
        width: 80px;
        height: 80px;
    }

    @media screen and (max-width: 480px) {
        width: 78px;
        height: 45px;
        margin-left: -20px;
    }
`;

const DownloadButton = styled.a`
    background: linear-gradient(135deg, #FF5A5A 0%, #FF8080 100%);
    padding: 1.2rem 2.4rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(255, 90, 90, 0.3);
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 20px rgba(255, 90, 90, 0.4);
        background: linear-gradient(135deg, #FF4040 0%, #FF6060 100%);
    }

    span {
        font-size: 1.4rem;
    }
`;

const HeroSection = () => {
    return (
        <HeroContainer id='home'>
            <HeroBg>
                <VideoBg
                    autoPlay={true}
                    loop={true}
                    controls={false}
                    playsInline
                    muted
                    src={"https://storage.googleapis.com/background-for-orchestra/northbeach.mp4"}
                    type='video/mp4'
                />
            </HeroBg>
            <AnimatedHeroContent>
                <HeroH1>
                    I <br />
                    F<LocationMarkerIcon />CKING
                    <br />
                    <span>HATE PARKING</span>
                </HeroH1>
                <HeroP>
                    Find parking instantly in SF
                </HeroP>
                {/* <HeroP style={{ fontSize: '80px', marginTop: '10px' }}>
                    <FloatingEmoji>🌉</FloatingEmoji>
                </HeroP> */}
                <HeroBtnWrapper>
                    <DownloadButton
                        href="https://apps.apple.com/us/app/parkingkarma-sf/id6470849458"
                        target="_blank"
                    >

                        Download the free app

                    </DownloadButton>
                </HeroBtnWrapper>
            </AnimatedHeroContent>
        </HeroContainer>
    );
};

export default HeroSection;
