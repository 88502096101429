import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaParking, FaShieldAlt, FaVideo } from 'react-icons/fa';
import directions from "../../images/directions.png";
import map from "../../images/seemap.png";
import walkinguphills from "../../images/walkinguphills.png";
import parkingrules from "../../images/parkingschedule.png";
import protectcar from "../../images/protect.png";
import metermaid from "../../images/metermaid.png";

const Section = styled.section`
  padding: 180px 24px;
  background: linear-gradient(180deg, #000000 0%, #0A0A0A 100%);
  color: #fff;
  position: relative;
  overflow: hidden;
  
  @media screen and (max-width: 768px) {
    padding: 100px 20px;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 120px;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 80px;
  }
`;

const Overline = styled.span`
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: linear-gradient(135deg, #FF5A5A 0%, #FF8080 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  margin-bottom: 24px;
  display: block;
`;

const Title = styled.h2`
  font-size: clamp(40px, 5vw, 56px);
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 24px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
`;

const FeatureRow = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
  margin-bottom: 160px;
  position: relative;
  
  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 968px) {
    flex-direction: column !important;
    text-align: center;
    margin-bottom: 120px;
    gap: 60px;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
  max-width: 480px;

  @media screen and (max-width: 968px) {
    max-width: 100%;
  }
`;

const FeatureTag = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background: rgba(255, 90, 90, 0.1);
  color: #FF5A5A;
  border-radius: 100px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 24px;
  border: 1px solid rgba(255, 90, 90, 0.2);
`;

const FeatureTitle = styled.h3`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #fff;
  line-height: 1.3;
`;

const FeatureDescription = styled.p`
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.7;
  font-size: 17px;
  margin-bottom: 32px;
`;

const FeatureImage = styled(motion.img)`
  width: 100%;
  max-width: 480px;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  
  @media screen and (max-width: 968px) {
    max-width: min(480px, 90vw);
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 12px;
`;

const FeatureListItem = styled.li`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  
  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background: rgba(255, 90, 90, 0.1);
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
  }

  &::after {
    content: '→';
    position: absolute;
    color: #FF5A5A;
    font-size: 12px;
    margin-left: 4px;
  }
`;

const DownloadButton = styled(motion.button)`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: #FF5A5A;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  margin-top: 24px;
  border: none;
  cursor: pointer;
  box-shadow: 0 8px 20px rgba(255, 90, 90, 0.25);

  &:hover {
    background: #FF4040;
  }
`;

const scrollToPricing = () => {
  const element = document.getElementById('pricing');
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
};

const Features = () => {
  return (
    <Section id="second-page">
      <Container>
        <SectionHeader>
          <Overline>Premium Features</Overline>
          <Title>Never waste time on parking again.</Title>
          <Subtitle>
            Save time and money.
          </Subtitle>
        </SectionHeader>

        <FeatureRow
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <FeatureContent>
            <FeatureTag>Live Availability</FeatureTag>
            <FeatureTitle>Find Open Spots Instantly</FeatureTitle>
            <FeatureList>
              <FeatureListItem>AI-powered camera network</FeatureListItem>
              <FeatureListItem>Real-time parking updates</FeatureListItem>
              <FeatureListItem>Never circle the block again</FeatureListItem>
            </FeatureList>
            <DownloadButton
              onClick={() => window.open('https://apps.apple.com/us/app/parkingkarma-sf/id6470849458', '_blank')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Download Free App
            </DownloadButton>
          </FeatureContent>
          <FeatureImage
            src={map}
            alt="Real-Time Parking Map"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          />
        </FeatureRow>

        <FeatureRow
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <FeatureContent>
            <FeatureTag>Smart Navigation</FeatureTag>
            <FeatureTitle>Turn-by-Turn Guidance</FeatureTitle>
            <FeatureList>
              <FeatureListItem>Precise routing to open spots</FeatureListItem>
              <FeatureListItem>ETA to available parking</FeatureListItem>
              <FeatureListItem>Best route suggestions</FeatureListItem>
            </FeatureList>
            <DownloadButton
              onClick={() => window.open('https://apps.apple.com/us/app/parkingkarma-sf/id6470849458', '_blank')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Download Free App
            </DownloadButton>
          </FeatureContent>
          <FeatureImage
            src={directions}
            alt="Turn-by-Turn Navigation"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          />
        </FeatureRow>

        {/* <FeatureRow
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <FeatureContent>
            <FeatureTag>Meter Maid Tracking</FeatureTag>
            <FeatureTitle>Avoid Parking Tickets (Beta)</FeatureTitle>
            <FeatureDescription>
              See meter maids in real-time to avoid parking tickets. Our app keeps
              you one step ahead with proactive alerts and notifications.
            </FeatureDescription>
            <FeatureList>
              <FeatureListItem>Live meter maid tracking</FeatureListItem>
              <FeatureListItem>Instant notifications</FeatureListItem>
              <FeatureListItem>Save hundreds in parking tickets</FeatureListItem>
            </FeatureList>
            <DownloadButton
              onClick={scrollToPricing}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              See Pricing
            </DownloadButton>
          </FeatureContent>
          <FeatureImage
            src={metermaid}
            alt="Meter Maid Tracking"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          />
        </FeatureRow> */}

        <FeatureRow
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <FeatureContent>
            <FeatureTag>Accident Protection</FeatureTag>
            <FeatureTitle>Protect Your Car</FeatureTitle>
            {/* <FeatureDescription>
              Protect your car with video evidence. If your parked car gets hit,
              we provide the footage for your insurance claims.
            </FeatureDescription> */}
            <FeatureList>
              <FeatureListItem>24/7 video protection for your car</FeatureListItem>
              <FeatureListItem>Request footage when your car is damaged</FeatureListItem>
              <FeatureListItem>Insurance claim support</FeatureListItem>
            </FeatureList>
            <DownloadButton
              onClick={() => window.open('https://apps.apple.com/us/app/parkingkarma-sf/id6470849458', '_blank')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Download Free App
            </DownloadButton>
          </FeatureContent>
          <FeatureImage
            src={protectcar}
            alt="Accident Protection Footage"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          />
        </FeatureRow>

        <FeatureRow
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <FeatureContent>
            <FeatureTag>Parking Rules</FeatureTag>
            <FeatureTitle>We Check the Rules for You</FeatureTitle>
            {/* <FeatureDescription>
              We decode the complex parking rules, showing schedules and
              restrictions at a glance.
            </FeatureDescription> */}
            <FeatureList>
              <FeatureListItem>Real-time rule updates</FeatureListItem>
              <FeatureListItem>Street cleaning schedules included</FeatureListItem>
              <FeatureListItem>Permit zone information</FeatureListItem>
            </FeatureList>
            <DownloadButton
              onClick={() => window.open('https://apps.apple.com/us/app/parkingkarma-sf/id6470849458', '_blank')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Download Free App
            </DownloadButton>
          </FeatureContent>
          <FeatureImage
            src={parkingrules}
            alt="Parking Schedule"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          />
        </FeatureRow>
      </Container>
    </Section>
  );
};

export default Features;