import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import { Nav, NavbarContainer, NavLogo } from './NavbarElements';
import parkingkarmalogo from "../../images/yin-yang-solid.svg";
import { FaTshirt } from 'react-icons/fa';
import styled from 'styled-components';

const Logo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
`;

const ShopBanner = styled.a`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(135deg, #FF5A5A 0%, #FF8080 100%);
  color: white;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  font-weight: 600;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.2);
  z-index: 1000;
  transition: all 0.3s ease;
  font-size: 1.1rem;
  
  &:hover {
    background: linear-gradient(135deg, #FF4040 0%, #FF6060 100%);
  }

  svg {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 14px;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 8px 16px;
  border-radius: 20px;
  
  &:hover {
    background: rgba(255, 90, 90, 0.1);
    color: #FF5A5A;
  }
`;

const Navbar = () => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => window.removeEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to='/' onClick={toggleHome}>
              <Logo src={parkingkarmalogo} alt="ParkingKarma Logo" />
              ParkingKarma
            </NavLogo>

            {/* <NavLinks>
                            <NavLink href="#parking">Find Parking</NavLink>
                            <NavLink href="#metermaids">Avoid Tickets</NavLink>
                            <NavLink href="#security">Car Security</NavLink>
                        </NavLinks> */}
          </NavbarContainer>
        </Nav>

        {/* <ShopBanner
          href="https://grove-seagull-613.notion.site/ParkingKarma-Merch-Order-Form-1403bb8551df80f1a43ee9b867985b35"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTshirt />
          Get the "I F*cking Hate Parking" T-shirt
        </ShopBanner> */}
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
