import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaDiscord } from 'react-icons/fa';

const Section = styled.section`
  min-height: 50vh;
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const Container = styled.div`
  max-width: 600px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 900;
  margin-bottom: 16px;
  background: linear-gradient(135deg, #5865F2 0%, #7289DA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.p`
  font-size: clamp(1rem, 2vw, 1.2rem);
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 24px;
  line-height: 1.5;
`;

const JoinButton = styled(motion.a)`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: #5865F2;
  color: white;
  padding: 16px 36px;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 8px 30px rgba(88, 101, 242, 0.3);
  
  &:hover {
    background: #4752C4;
    transform: translateY(-2px);
  }

  svg {
    font-size: 1.4rem;
  }
`;

const DiscordCommunity = () => {
  return (
    <Section>
      <Container>
        <Title>Join Our Discord</Title>
        <Description>
          Help shape the future of parking in San Francisco.
        </Description>
        <JoinButton
          href="https://discord.gg/r8X8WbKGUx"
          target="_blank"
          rel="noopener noreferrer"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <FaDiscord /> Join Our Discord
        </JoinButton>
      </Container>
    </Section>
  );
};

export default DiscordCommunity; 